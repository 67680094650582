<template>
	<div :class="themeClass" class="BillWallet-Box padding-lr-13 margin-t-20">
		<div style="display: flex; flex-direction: row; width: 100%;">
			<div class="title font-size18 font-color-333 font-weight700">票据钱包</div>
			<div class="position-absolute entity-account pointer font-weight700 font-size15 color-theme"
				@click="toEntityAccount">绑定账户</div>
		</div>

		<div v-if="cashList.length > 0">
			<el-carousel ref="carousel" trigger="click" :autoplay="false" type="card" height="200px" arrow="always"
				@change="linkTo">
				<el-carousel-item v-for="(item, index) in cashList" :key="index">
					<div class="flex-row position-relative">
						<div class="card font-color-fff flex-column-align-center"
							@click="getSelcTabList(item.fShopUnitID)">
							<div class="font-size15 font-weight700 margin-t-25">{{ item.fShopName }}</div>
							<div class="font-size12">当前余额</div>
							<div class="font-size22 font-weight700">¥{{ item.fEndBalance | NumFormat }}</div>
							<div class="font-size12 color-87ffdd">汇票到期日:{{ item.fMaxEndDate }}</div>
							<div class="margin-t-17 flex-row">
								<div class="button-box-TX flex-row-center-center margin-r-10 pointer"
									@click="toRecharge(item)">提现</div>
								<div class="button-box-CZ flex-row-center-center pointer" @click="toC(item)">充值</div>
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>

			<div class="tab text-center margin-t-10 flex-row-space-between">
				<div class="flex-row">
					<div class="tab-item pointer"
						:class="tabSelect == 0 ? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2' : ''"
						@click="tabClick(0)">充值明细</div>
					<div class="tab-item pointer"
						:class="tabSelect == 1 ? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2' : ''"
						@click="tabClick(1)">提现明细</div>
				</div>
				<!-- <div class="mycard background-color-theme font-color-fff">我的银行卡</div> -->
			</div>
			<div v-if="tabSelect == 0">
				<div class="bill" v-for="(item, index) in detailData" :key="'info3-' + index">
					<div class="bill-item border-b-F2F2F2">
						<div class="flex-row-space-between lh24">
							<div class="font-size14">
								票据钱包充值(单号:&nbsp;{{ item.fCheckInBillCode }})
								<span
									class="audit text-center margin-l-10 bg-FF9900 font-color-fff font-size12">{{ item.fApproveStatusName }}</span>
							</div>
							<div class="font-size14">¥{{ item.fMoney | NumFormat }}</div>
						</div>
						<div class="flex-row-space-between lh24">
							<div class="font-color-999 font-size12">{{ item.fCreateTime }}</div>
							<!--div v-if="item.fAuditingStatus == 254"
								class="operation-btn font-size12 text-center border border-color-theme color-theme pointer"
								@click="downLoad(item.fCheckInBillID)">
								下载收据
							</div-->
						</div>
					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="total,prev, pager, next" :page-size="pagesizet" :total="totalt"
						@current-change="handleCurrentChanget"></el-pagination>
				</div>
			</div>
			<div v-if="tabSelect == 1">
				<div class="bill">
					<div class="bill-item border-b-F2F2F2" v-for="(item, index) in detailData1" :key="'info2-' + index">
						<div class="flex-row-space-between lh24">
							<div class="font-size14">
								票据钱包提现(单号:&nbsp;{{ item.fRefundBillNumber }})
								<span
									class="audit text-center margin-l-10 bg-FF9900 font-color-fff font-size12">{{ item.fApproveStatusName }}</span>
							</div>
							<div class="font-size14">¥{{ item.fRefundMoney | NumFormat }}</div>
						</div>
						<div class="flex-row-space-between lh24">
							<div>
								<div class="font-color-999 font-size12">
									{{ item.fShopName }}
									<span class="font-color-999 font-size12 margin-l-10">{{ item.fCreateTime }}</span>
								</div>
							</div>
							<div v-if="item.fIsNeedUpload == 1">
								<div @click="openUploaders(item)" style="padding: 0px 6px;font-size: 12px;cursor: pointer;background-color: #409EFF;color: #fff;" class="font-size14">下载/上传退款申请</div>
							</div>
							<div v-if="item.fIsNeedUpload == 2">
								<div style="padding: 0px 6px;font-size: 12px;cursor: pointer;background-color: #409EFF;color: #fff;" class="font-size14"  @click="showImgClick(item.fAnnex)" >查看附件</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination flex-row-center-flex-end ">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxResultCount"
						:total="total" @current-change="handleCurrentChange"></el-pagination>
				</div>
			</div>
		</div>
		<div class="text-center" v-else>
			<svg-icon icon-class="NoRightsAndInterests" />
			<div class="font-color-666 font-size16">您尚未开通票据钱包，请前往企业购议价申请 !</div>
			<div class="open text-center background-color-theme pointer" @click="openAccountPay('开通票据')">开通票据钱包</div>
		</div>
		<!-- 聚材公司关于收取承兑汇票的要求 -->
		<el-dialog :visible.sync="introduceShow" :close-on-click-modal="false" :close-on-press-escape="false"
			class="introduceDialog" :class="showIntroduceType==1?'introducePict':'introduceContent'">
			<div style="line-height: 30px; height: 50vh; overflow-y: scroll;">
				<div class="headline">聚材公司关于收取承兑汇票的要求</div>
				<div style="color: red;">请前往企业网银进行票据充值！</div>
				<div>一、不收取商业承兑汇票。</div>
				<div>二、收取银行承兑汇票的标准：</div>
				<div>1、单张票据金额应控制在{{Money}}万元(含)以下</div>
				<div>2、到期日应控制在{{month}}月(含)以内</div>
				<div>3、可直接收取银行承兑汇票出具银行（{{row}}）家为:{{White}}。</div>
				<div>4、出票银行属于股份公司指定不予接收的银行，禁止收取，指定银行名称随市场环境变化不定期更新：</div>
				<el-table :data="tableDetalis" :header-cell-style="{ 'text-align': 'center', background: '#F7F9FE' }"
					border>
					<el-table-column v-for="(item, index) in tableLabel" :key="index" :prop="item.prop"
						:width="item.width" :label="item.label" :align="item.align"></el-table-column>
				</el-table>
				<div>5、单张票据金额超{{Money}}万元、到期日超{{month}}月、除可直接收取的（{{row}}）家国股银行承兑汇票且不是股份公司规定不容许收取的银行汇票的都需要领导审批，批准后方可收取。
				</div>
			</div>
			<div style="position: relative; top: 10px; left: 90%; width: 80px; font-weight: 600; text-align: center;">
				<p>聚材公司</p>
				<p>2020年3月</p>
			</div>
		</el-dialog>
		<!--上传文件的弹框-->
		<!--上传文件的弹框-->
		<el-dialog v-if='showUploaderModel' title="下载/上传退款申请" top="5vh" :visible.sync="showUploaderModel" :close-on-click-modal="false"   width="30%" :before-close="updhandleClose">
			
			<div ref="dialogBox" class="dialogScroll">
				<!-- -->
				<el-form ref="form"   :model="uploadforms" :label-width="formLabelWidth" label-position="left">
					<el-form-item label="提现单号" prop="fRefundBillNumber" style="position: relative;">
						<el-input v-model="uploadforms.fRefundBillNumber" disabled></el-input>
					</el-form-item>
					<el-form-item label="提现金额" prop="fRefundGoodsMoney" style="position: relative;">
						<el-input v-model="uploadforms.fRefundGoodsMoney" disabled></el-input>
					</el-form-item>
					<div v-for="(item, index) in imgObj" :key="index"> 
						<el-form-item :label="item.rule.fRuleName"  :class="item.rule.fIsNecessary==0 ? '': 'isNecessary'" prop="fRefundGoodsMoney" style="position: relative;height: 210px;overflow: hidden;">
							<div class="flex-row">
									<div class="form-box-in">
										<div style="width: 150px;height: 150px;overflow: hidden;">
											<el-upload
											  :action="actionback"
											  list-type="picture-card"
											  :on-remove="handleRemove"
											  :on-success="handlesuceess"
											  :before-upload='beforeupload'
											  :data="uploadToken[index]"
											  :multiple="false" 
											  :limit="1"
											  :accept="item.rule.fFileType"
											  :disabled="isshow"
											  >
											  <i class="el-icon-plus" @click="handlebeforeupload(item.rule)"></i>
											</el-upload>
										</div>
		
									<div data-v-4d9d131f="" class="font-color-FF9900 font-size12">*提示：仅支持上传{{item.rule.fFileType}}格式文件；</div>
								</div>
							</div>
						</el-form-item>
					</div>
					<div data-v-4d9d131f="" class="font-color-FF9900 font-size12 margin-b-10">请将退款申请函及收据原件寄至:{{addressshow}} 电话:{{phoneshow}}</div>
					<el-form-item label="" style="text-align: right;">
						  <div @click="getonDownload" style="padding: 0px 6px ; border: 1px #eee solid;text-align: center;display: inline-block;font-size: 12px;height: 32px;line-height: 32px;border-radius: 5px;background-color: #409EFF;color: #fff;cursor: pointer;">下载申请函模板</div>
					</el-form-item>
					
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updhandleClose">取 消</el-button>
				<el-button type="primary" @click="subforms">确 定</el-button>
			</span>
		</el-dialog>
		<ImageViewer v-if="showImg" :srcList="showObj" :index="0" @close="showImg = false"></ImageViewer>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import pagination from '@/components/pagination/pagination.vue';
	import uploadFile from "@/utils/uploadFile";
	import ImageViewer from '@/components/upload/ImageViewer.vue'  //查看附件
	export default {
		components:{
				ImageViewer
		},
		data() {
			return {
				showObj: [], //点击放大的图片路径
				showImg: false, //图片是否显示
				//弹框数据
				showUploaderModel:false,
				formLabelWidth: '100px',
				filesetID:"",
				isshow:false,
				actionback:"",
				// 表单绑定数据
				uploadforms:{
					fRefundGoodsMoney:"", //提现金额
					fRefundBillNumber:"",// 提现编号
				},
				imgObj: [], // 图片规则
				ruledom:{}, //每次需要上传组织的数据的rule
				addressshow:"",
				phoneshow:"",
				uploadToken:[],
				uploadFileAshx:"",
				annexServerUrl: '',
				fAnnexTypeIDData: [],
				fIsNecessaryData: [],//必传数据
				accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
				uploadDir:"Web/SupplyDemand",
				imglistback: [], //上传整理后的图片数组
				tableLabel: [{
						label: '',
						width: '50',
						prop: 'code',
						align: 'center',
						fixed: true
					},
					{
						label: '银行名单',
						// width: '80',
						prop: 'bankName',
						align: 'left',
						fixed: true
					}
				],
				fAccountID: '', //存储编辑跳转需要的ID
				bindType: 'Add', //绑定状态(用于判断绑定还是修改)
				currentPaget: 1, //当前页
				pagesizet: 6, //每页显示10条数据
				totalt: 0, //总数据
				maxResultCount: 6, //每页显示条数
				page: 1, //页数
				total: 0,
				tabSelect: 0,
				fEnableDelete: '',
				cashdetails: [],
				isAllCheck: false,
				listShow: 0,
				isIndeterminate: false,
				cashList: [],
				detailData: [], //充值单明细
				detailData1: [], //提现单明细
				currentPage: 1, //当前页
				pagesize: 10, //每页显示10条数据
				introduceShow: false, //弹窗
				showIntroduceType: 0, //首页活动弹窗类型
				introduceContent: '', //首页活动富文本内容
				introduceRule: [],
				tableData: [],
				tableDetalis: [],
				UnitID: '',
				month: 'X', //月份
				Money: 'X', //金额
				row: 0,
				White: 'XXXX', //银行白名单
				//展示当前列表信息

				mergeDataone: [],
				mergeData: [{
						id: '1',
						num: '2020080312458976',
						date: '2020-12-20',
						invoiceAmount: '235060', //出票金额
						balance: '98560.5', //余额
						enddate: '2020-12-20',
						checked: true
					},
					{
						id: '2',
						num: '2020080312458976',
						date: '2020-12-20',
						invoiceAmount: '235060', //出票金额
						balance: '98560.5', //余额
						enddate: '2020-12-20',
						checked: true
					},
					{
						id: '3',
						num: '2020080312458976',
						date: '2020-12-20',
						invoiceAmount: '235060', //出票金额
						balance: '98560.5', //余额
						enddate: '2020-12-20',
						checked: false
					}
				],
				tabData: [{
						title: '票据账单',
						type: 1,
						checked: true
					},
					{
						title: '票据合并',
						type: 2,
						checked: false
					},
					{
						title: '票据提现',
						type: 3,
						checked: false
					}
				],
				shopID: ''
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			this.getCashList();
			this.checkBinding();
			this.getImgUrl();
			// this.getRechargeDetails()
			// this.getWithdrawalDetails()
		},
		methods: {
			//查看附件图片
			showImgClick(val) {
				let arrayFul = []
				if(val.length !=0){
					for(let item of val){
						arrayFul.push(item.fullFPath)
					}
				}
				this.showObj = arrayFul;
				this.showImg = true;
			},
			// 打开弹框
			openUploaders(id){
				this.getaddress()
				let params ={
					id:id.fRefundBillID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/get',params).then(
					result => {
						console.log(result ,"获取接口2 数据")
						// this.uploadforms.name = result.
						this.uploadforms.fRefundGoodsMoney = result.obj.fRefundGoodsMoney //提现金额
						this.uploadforms.fRefundBillNumber = result.obj.fRefundBillNumber // 提现编号
						this.filesetID = id
						this.getuploaderrules(id)
					},
					rej => {}
				);
				this.imglistback = []
				this.showUploaderModel = true
			},
			// 确定
			subforms(){
				// if(this.imglistback.length == 0){
				// 	this.$message.error("请上传图片")
				// 	return
				// }
				if (this.fIsNecessaryData.length > 0) {
					var falg = true
					this.fIsNecessaryData.forEach(item => {
						if (!this.imglistback[item.index]) {
							falg = false
						}
					})
					if(falg == false){
						this.$message.error("请上传图片")
						return
					}
				}
				let arr = []
				for(let item of this.imglistback){
					arr.push({
						fRuleID:item.fRuleID,
						fAnnexTypeID:item.fAnnexTypeID,
						fPath:item.fPath,
						fSourceFileName:item.fSourceFileName,
						fFileExName:item.fFileExName,
					})
				}
				let params = {
					 fRefundBillID: this.filesetID.fRefundBillID,
					 annexList: arr
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/create-annex-offline-refund-bill',params).then(
					result => {
						console.log(result ,"获取接口5 数据")
						if(result.code =  "HengHeCode1000"){
							this.showUploaderModel = false
							this.$message.success("上传成功")
							this.getWithdrawalDetails()
							this.uploadforms.fRefundGoodsMoney = ""
							this.uploadforms.fRefundBillNumber = ""
							this.imglistback = []
						}
					},
					rej => {}
				);
			},
			// 弹框关闭
			updhandleClose(){
				this.showUploaderModel = false
			},
			getImgUrl() {
				//上传图片前缀
				this.ApiRequestPostNOMess(
					"/api/mall/ebbase/para-value/get-config"
				).then(
					(result) => {
						console.log("result返回的config结果", result)
						this.annexServerUrl = result.obj.annexServerUrl
						this.uploadFileAshx = result.obj.annexServerUrl + 'upload';
						
						this.uploadActionURL()
					},
					(rej) => {}
				);
			},
			// 获取action
			uploadActionURL() {
				this.actionback =  uploadFile.makeUploadActionURL(this.uploadDir, {
					ashx: this.uploadFileAshx,
				});
				console.log("返回的连接",this.actionback)
			},
			// 文件上传成功的
			handlesuceess(response, file, fileList){
				console.log("上传成功的回调函数response",response)
				console.log("上传成功的回调函数file",file)
				console.log("上传成功的回调函数fileList",fileList)
				console.log("现在的ruleID",this.ruledom)
				this.isshow = false
				this.ruledom.fPath = response.fileName
				this.ruledom.fSourceFileName = file.name
				this.ruledom.fFileExName = file.name.split('.')[0]
				this.ruledom.uid = file.uid
				this.imglistback.push(this.ruledom)
				console.log(this.imglistback,'查看里面的数据')
				
			},
			beforeupload(){
			  this.isshow = true
			},
			handlebeforeupload(e){
				if(this.isshow == true){
					return
				}
				this.ruledom={
					fAnnexTypeID: e.fAnnexTypeID,
					fBillTypeID: e.fBillTypeID,
					fRuleID: e.fRuleID,
					frv: e.frv
				}
			
			},
			// 移除
			handleRemove(file, fileList) {
			        console.log(file, fileList);
					console.log("删除图片数据",file)
					console.log("删除之前的数据",this.imglistback)
					for(var i =0; i< this.imglistback.length;i++){
						if(this.imglistback[i].uid == file.uid){
							this.imglistback.splice(i,1)
						}
					}
					console.log("删除完的数据",this.imglistback)
			      },
			//获取图片上传规则
			getuploaderrules(id){
				let params = {
					fRefundBillID: id.fRefundBillID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/get-annexrule-offline-refund-bill',params).then(
					result => {
						
						console.log(result, '获取图片上传规则');
						console.log(result.obj)
						this.imgObj = result.obj
						this.imgObj.forEach((item, index) => {
							this.uploadToken.push({token:item.token})
							this.fAnnexTypeIDData.push(item.rule)
						})
						this.fIsNecessaryData = []
						for (let i = 0; i < this.imgObj.length; i++) {
							if (this.imgObj[i].rule.fIsNecessary == 1) {
								this.fIsNecessaryData.push({
									fRuleID: this.imgObj[i].rule.fRuleID,
									fRuleName: this.imgObj[i].rule.fRuleName,
									index: i
								})
							}
						}
						console.log('获取图片上传规则')
					},
					rej => {}
				);
			},
			// 获取寄回电话和寄回地址
			getaddress(){
				this.ApiRequestPostNOMess(
					"/api/mall/ebshop/baseinfo/get-self-support-shop"
				).then(
					(result) => {
						console.log("result返回的config结果", result)
						this.addressshow = result.obj.fAddress
						this.phoneshow = result.obj.fcsPhone
					},
					(rej) => {}
				);
			},
			// 模板下载
			getonDownload(){
				let params = {
					fRefundBillID:this.filesetID.fRefundBillID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/print-refund-letter',params).then(
					result => {
						console.log("下载元素",result)
						window.open(result.obj)
					},
					rej => {}
				);
			},
			openAccountPay(val) {
				// fCommerceTypeID:1 个人   2 企业
					this.$router.replace({
						path: '/businessme/OpenBillWallet',
						query: {
							title: val,
							type: 2,
							fAccountname: '开通票据钱包',
						},
					})
			},
			handleCurrentChange(val) {
				this.page = val;

				this.getWithdrawalDetails();
			},
			handleCurrentChanget(val) {
				this.currentPaget = val;

				this.getRechargeDetails();
			},
			//
			linkTo() {
				let activeIndex = this.$refs.carousel.activeIndex;
				// console.log(this.$refs.carousel);
				// console.log(activeIndex);
			},
			// tab切换
			tabClick(val) {
				this.tabSelect = val;

				if (this.tabSelect == 0) {
					this.getRechargeDetails(this.shopID);
				} else {
					this.getWithdrawalDetails(this.shopID);
				}
			},
			getSelcTabList(e) {
				if (this.tabSelect == 0) {
					this.getRechargeDetails(e);
				} else {
					this.getWithdrawalDetails(e);
				}
			},

			// 获取我的钱包
			getCashList() {
				this.ApiRequestPostNOMess('api/mall/ebbalance/customer-acceptance/get-my-acceptance').then(
					result => {
						this.cashList = result.obj;
						if (this.cashList.length > 0) {
							this.shopID = this.cashList[0].fShopUnitID;
							this.getRechargeDetails(this.shopID);
						}
						this.cashList.forEach(item => {
							item.fMaxEndDate = this.getDate(item.fMaxEndDate);
						});
					},
					rej => {}
				);
			},
			// 获取充值明细
			getRechargeDetails(e) {
				if (e) {
					this.shopID = e;
				}
				this.ApiRequestPostNOMess('api/mall/ebcn/note-check-in/get-note-recharge-list-by-shopid', {
					fShopUnitID: this.shopID,
					maxResultCount: this.pagesizet,
					skipCount: (this.currentPaget - 1) * this.pagesizet
				}).then(
					result => {
						this.detailData = [];

						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
						}
						this.detailData = result.obj.items;
						this.totalt = result.obj.totalCount;
					},
					rej => {}
				);
			},
			// 获取提现明细
			getWithdrawalDetails(e) {
				if (e) {
					this.shopID = e;
				}

				this.ApiRequestPostNOMess('api/mall/ebsale/refund-bill/get-list-by-stiff', {
					fShopUnitID: this.shopID,
					maxResultCount: this.maxResultCount,
					filter: '',
					skipCount: (this.page - 1) * this.maxResultCount
				}).then(
					result => {
						this.detailData1 = [];
						this.detailData1 = result.obj.items;
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
						}
						this.total = result.obj.totalCount;
					},
					rej => {}
				);
			},
			//tab点击事件
			selecttab(e) {
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.listShow = e;
			},
			//跳转交易明细
			toTransactionDetails() {
				//this.$router.push('/PersonalCenter/pages/CashWallet/TransactionDetails')
			},
			checkChange(val, index) {
				let haveFalse = false;
				for (let item of this.mergeData) {
					if (item.checked == false) {
						this.isAllCheck = false;
						/* haveFalse = true; */
						break;
					} else {
						this.isAllCheck = true;
					}
				}
			},
			allCheckAllChange(val) {
				if (this.isAllCheck) {
					for (let data of this.mergeData) {
						data.checked = true;
					}
				} else {
					for (let data of this.mergeData) {
						data.checked = false;
					}
				}
			},
			handleScroll(index) {
				//this.$refs.sortMenu.scrollLeft = index * 323;
				//console.log(index,this.$refs.sortMenu.scrollLeft,"dfjkjoiljko");
			},
			//提现跳转
			toRecharge(e) {
				// console.log(e);
				let obj = {
					title: '票据提现',
					walletType: 'PJ',
					shopName: e.fShopName,
					maxAmount: e.fEndBalance,
					shopID: e.fShopUnitID
				};
				this.$router.push({
					path: '/businessme/ReachCreditAndTicket',

					query: {
						// data: encodeURIComponent(JSON.stringify(obj))
						data: JSON.stringify(obj)
					}
				});
			},
			// toC(e) {
			// 	let obj = {
			// 		title: '票据充值',
			// 		walletType: 'PJ',
			// 		shopName: e.fShopName,
			// 		maxAmount: e.fEndBalance,
			// 		shopID: e.fShopUnitID
			// 	};
			// 	this.$router.push({
			// 		path: '/businessme/RechargeTicketForm',
			// 		query: {
			// 			// data: encodeURIComponent(JSON.stringify(obj))
			// 			data: JSON.stringify(obj)
			// 		}
			// 	});
			// },
			toC(e) {
				// console.log(e,"点击数据")
				// console.log(e.fShopUnitID,"点击ID")
				this.UnitID = e.fShopUnitID
				this.ApiRequestPostNOMess('/api/mall/ebcn/note-check-in/get-shop-is-sure-recharge', {
					fShopID: e.fShopUnitID
				}).then(
					result => {
						console.log(result.obj, "拿到结果")
						if (result.obj == 1) {
							let obj = {
								title: '票据充值',
								walletType: 'PJ',
								shopName: e.fShopName,
								maxAmount: e.fEndBalance,
								shopID: e.fShopUnitID
							};
							this.$router.push({
								path: '/businessme/RechargeTicketForm',
								query: {
									// data: encodeURIComponent(JSON.stringify(obj))
									data: JSON.stringify(obj)
								}
							});
						} else {
							// this.$message.info("请前往企业网银进行票据充值!")
							this.introduceShow = true;
							this.White = [];
							this.tableDetalis = [];
							this.getMoney(); //获取金额
							this.getYears(); //获取年月
							this.getWhiteList(); //获取白名单
							this.getBlackList(); //获取黑名单
						}
					},
					rej => {}
				);

			},
			//获取金额
			getMoney() {
				this.ApiRequestPostNOMess('/api/mall/ebbase/para-value/get-shop-bill-limit-money', {
					fShopID: this.UnitID
				}).then(
					result => {
						console.log(result, "拿到金额")
						let sums = result
						this.Money = sums / 10000
					},
					rej => {}
				);
			},
			//获取年月
			getYears() {
				this.ApiRequestPostNOMess('/api/mall/ebbase/para-value/get-shop-bill-over-days', {
					fShopID: this.UnitID
				}).then(
					result => {
						console.log(result, "拿到年月")
						this.month = result
					},
					rej => {}
				);
			},
			//获取白名单
			getWhiteList() {
				this.ApiRequestPostNOMess('/api/mall/ebcn/black-white-noter/get-white-list', {}).then(
					result => {
						console.log(result.obj, "拿到白名单")
						console.log(result.obj.length, "拿到白名单条数")
						var str = "";
						for (var item of result.obj) {
							this.White = this.White + item.fBlackWhiteNoterName + '、'
						}
						this.White = this.White.substr(0, this.White.length - 1)
						this.row = result.obj.length
					},
					rej => {}
				);
			},
			//获取黑名单
			getBlackList() {
				this.ApiRequestPostNOMess('api/mall/ebcn/black-white-noter/get-black-group', {}).then(
					result => {
						console.log(result.obj, "拿到黑名单")
						this.tableData = result.obj
						console.log(JSON.stringify(this.tableDetalis), "666666666666666")
						for (var item of this.tableData) {
							var str = "";
							for (var detail of item.list) {
								str += detail.fBlackWhiteNoterName + "、";
							}
							str = str.slice(0, str.length - 1)
							this.tableDetalis.push({
								code: item.fHelpCode,
								bankName: str
							})

						}
						console.log(this.tableDetalis, "b44444444444444")
					},
					rej => {}
				);
			},
			//下载收款单
			downLoad(e) {
				this.ApiRequestPostNOMess('/api/mall/ebcn/note-check-in/down-note-receipt', {
					fCheckInBillID: e
				}).then(
					result => {
						if (result.obj) {
							window.open(result.obj, '_blank');
						}
					},
					rej => {}
				);
			},
			toEntityAccount() {
				this.$router.push({
					path: '/businessme/entityAccount',
					query: {
						ID: this.fAccountID,
						Type: this.bindType
					}
				});
			},
			checkBinding() {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/bank-account/get-acceptance-physical-account').then(
					result => {
						if (result.obj) {
							this.bindType = 'Edit';
							this.fAccountID = result.obj.fAccountID;
						}
					},
					rej => {}
				);
			}
		}
	};
</script>

<style lang="scss" scoped="scoped">
	// .el-carousel__item:nth-child(2n) {
	//   background-color: #059b69;
	// }

	// .el-carousel__item:nth-child(2n-1) {
	//   background-color: #059b69;
	// }

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.flex-row-Delete {
		width: 57px;
		height: 23px;
		border-radius: 10px;
		text-align: center;
		line-height: 23px;
		// margin-left:95px;
	}

	.margin-l-6 {
		margin-left: 6px;
	}

	.border-b-F2F2F2 {
		border-bottom: 1px solid #f2f2f2;
	}

	.border-t-f2f2f2 {
		border-top: 1px solid #f2f2f2;
	}

	.lh24 {
		height: 24px;
		line-height: 24px;
	}

	.BillWallet-Box {
		width: 100%;
		height: calc(100% - 10px);
		// background-color: #ff3040;
		background-color: #ffffff;
	}

	.title {
		overflow: hidden; //解决盒子塌陷
		margin: 8px 0;
		height: 20px;
		line-height: 20px;
	}

	.color-87ffdd {
		color: #87ffdd;
	}

	.button-box-TX {
		width: 118px;
		height: 38px;
		border-radius: 2px;
		border: 1px solid #ffffff;
		user-select: none;
	}

	.button-box-CZ {
		width: 120px;
		height: 40px;
		border-radius: 2px;
		color: #4180dc;
		background-color: #ffffff;
		user-select: none;
	}

	.card {
		border-radius: 5px;
		margin-right: 20px;

		position: relative;
		width: 440px;
		height: 175px;
		border-radius: 5px;
		box-sizing: border-box;
		overflow: hidden;
		background-image: url(../../../assets/pjbg.png);
		box-shadow: 2px 2px 10px #025a3d;

		.bg {
			position: absolute;
			width: 450px;
			height: 173px;
			z-index: -1;
			opacity: 0.3;
		}

		.shopName {
			height: 15px;
			line-height: 15px;
		}

		.money {
			margin-top: 5px;
			margin-right: 6px;
			height: 22px;
			line-height: 22px;
		}

		.card-btn {
			height: 24px;
			line-height: 24px;
			border: 1px solid #fff;
			position: absolute;
			left: 280px;
			top: 125px;
			cursor: pointer;

			.btn1,
			.btn2 {
				display: inline-block;
				width: 75px;
				height: 25px;
			}
		}
	}

	.tab {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background-color: #f8f9fe;

		.tab-item {
			width: 108px;
			height: 40px;
		}

		.mycard {
			float: right;
			width: 110px;
			height: 30px;
			line-height: 30px;
			margin: auto 13px;
		}
	}

	.bill {
		.bill-item {
			height: 65px;
			padding: 10px 13px;
			box-sizing: border-box;

			.audit {
				display: inline-block;
				width: 50px;
				height: 17px;
				line-height: 17px;
			}
		}
	}

	.pagination {
		margin-top: 20px;
		float: right;
	}

	.operation-btn {
		width: 65px;
		height: 20px;
		line-height: 20px;
		border-radius: 10px;
	}

	.entity-account {
		text-decoration: underline;
		right: 400px;
		top: 190px;
		z-index: 1;
	}

	.headline {
		width: 100%;
		height: 50px;
		font-size: 18px;
		font-weight: 800;
		// background-color: red;
		line-height: 50px;
		display: flex;
		justify-content: center;
	}

	::v-deep .introduceDialog {
		overflow: hidden !important;
	}
	
	.open {
		width: 235px;
		height: 45px;
		line-height: 45px;
		margin: 23px auto;
	}
	/deep/ .el-upload--picture-card{
		position:relative !important;
	}
	.el-upload--picture-card i{
		font-size: 28px;
		    color: #8c939d;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    top: 0;
		    left: 0;
		    line-height: 136px;
	}
	.isNecessary:before {
		content: "*";
		position: absolute;
		color: red;
		top: 0px;
		left: 80px;
		font-size: 16px;
		font-weight: 700;
	}
</style>
